import {handleActions} from 'redux-actions'
import _ from 'lodash'
import { capitalize } from '../../../utils'
// import {select} from 'redux-saga/effects'

import {
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_VEHICLES,
  ROUTE_ADMIN_COMPANIES,
  ROUTE_ADMIN_LOCATIONS,
  ROUTE_ADMIN_PRICING_TIERS,
  ROUTE_ADMIN_REVIEWS,
  ROUTE_ADMIN_DRIVERS,
  ROUTE_ADMIN_RESERVATIONS,
  ROUTE_ADMIN_RESERVATIONS_OPTIMIZED,
  ROUTE_ADMIN_SETTINGS,
  ROUTE_ADMIN_MARKETS,
  ROUTE_ADMIN_PROMOTIONS,
  ROUTE_ADMIN_ZIPCODES,
  ROUTE_ADMIN_DGRAPHS,
  ROUTE_ADMIN_COMM_EVENTS,
  ROUTE_ADMIN_MODEL_VALIDATIONS,
  ROUTE_ADMIN_PENDING_ACCOUNTS,
  ADMIN_FETCH_DOCS_SUCCEEDED,
  ADMIN_CACHE_REQUEST_MODEL,
  ADMIN_REFRESH_REQUEST_MODEL,
  ADMIN_SET_REFRESH_FN,
  ADMIN_SET_REQUEST_META,
  ADMIN_REFRESH_SUCCEEDED,
  ROUTE_ADMIN_ANALYTICS,
  ROUTE_ADMIN_FIND,
  ADMIN_UPDATE_DISPLAY_OPTIONS,
  ROUTE_ADMIN_PENDING_VEHICLES,
  ROUTE_ADMIN_CHATS,
  ROUTE_ADMIN_MULTI_VEHICLE_REQUEST,
  ROUTE_ADMIN_BULK_VEHICLE_EDITS,
  ROUTE_ADMIN_BULK_RESERVATION_EDITS,
  ROUTE_ADMIN_CASH_PAYMENTS,
} from 'types'

let requestId = 0
function * generateRequestId () {
  yield requestId++
}

const initialState = {
  isNew: false,
  isSingle: false,
  isPending: false,
  models: [],
  modelId: null,
  assoc: null,
  requestId: null,    // OMG this is so freaking awkward
  refreshFn: null,
  names: {
    singular: '',
    plural: ''
  },
  title: '',
  displayOptions: {
    showArchived: false,
    showDeleted: false
  },
  match: null,
  matchedDocs: 0,
  meta: {
    sort: null,
    skip: 0,
    limit: 0,     // aka "no limit" ...
    project: null
  }
}

const nameMap = {
  [ROUTE_ADMIN_USERS]: { singular: 'user', plural: 'users' },
  [ROUTE_ADMIN_VEHICLES]: { singular: 'vehicle', plural: 'vehicles' },
  [ROUTE_ADMIN_COMPANIES]: { singular: 'company', plural: 'companies' },
  [ROUTE_ADMIN_LOCATIONS]: { singular: 'location', plural: 'locations' },
  [ROUTE_ADMIN_PRICING_TIERS]: { singular: 'Pricing Tier', plural: 'Pricing Tiers' },
  [ROUTE_ADMIN_REVIEWS]: { singular: 'review', plural: 'reviews' },
  [ROUTE_ADMIN_DRIVERS]: { singular: 'driver', plural: 'drivers' },
  [ROUTE_ADMIN_RESERVATIONS]: { singular: 'reservation', plural: 'reservations' },
  [ROUTE_ADMIN_RESERVATIONS_OPTIMIZED]: { singular: 'reservation opt', plural: 'reservations opt' },
  [ROUTE_ADMIN_SETTINGS]: { singular: 'settings', plural: 'settings' },
  [ROUTE_ADMIN_MARKETS]: { singular: 'market', plural: 'markets' },
  [ROUTE_ADMIN_PROMOTIONS]: { singular: 'promotion', plural: 'promotions' },
  [ROUTE_ADMIN_ZIPCODES]: { singular: 'zipcode', plural: 'zipcodes' },
  [ROUTE_ADMIN_DGRAPHS]: { singular: 'd-Graph', plural: 'd-Graphs' },
  [ROUTE_ADMIN_ANALYTICS]: { singular: 'COOP Analytics', plural: 'COOP Analytics' },  // TODO: ahem
  [ROUTE_ADMIN_FIND]: { singular: 'Find', plural: 'Find' },
  [ROUTE_ADMIN_COMM_EVENTS]: { singular: 'Comm Event', plural: 'Comm Events' },
  [ROUTE_ADMIN_MODEL_VALIDATIONS]: { singular: 'Data Issue', plural: 'Data Issues' },
  [ROUTE_ADMIN_PENDING_ACCOUNTS]: { singular: 'Pending Account', plural: 'Pending Accounts' },
  [ROUTE_ADMIN_PENDING_VEHICLES]: { singular: 'Pending Vehicle', plural: 'Pending Vehicles' },
}

// TODO. this whole bit is weird.
// the assoc is optional but will be treated as id if there is no explicit id
const payloadId = (payload) => {
  let result = null
  if (payload) {
    result = payload.modelId ? payload.modelId : (payload.assoc ? payload.assoc : null)
  }
  return result
}

const shouldFakeId = { [ROUTE_ADMIN_SETTINGS]: true }

const reduceRoute = (state, action) => {
  const id = payloadId(action.payload)
  const hasId = (id !== null) || shouldFakeId[action.type]
  const isNew = (hasId && id === 'new')
  const names = Object.assign({}, nameMap[action.type])
  const title = isNew ? ('New ' + capitalize(names.singular)) : ''
  return {
    models: [],
    isNew: isNew,
    isPending: !isNew,    // if it's new there is no fetch to make
    isRefreshing: false,
    isSingle: hasId,
    modelId: id,
    requestId: generateRequestId().next().value,
    refreshFn: null,
    assoc: null,
    names: names,
    title: title,
    displayOptions: {
      ...state.displayOptions
    },
    meta: {
      ...state.meta
    }
  }
}

// To be used when a view like (Analytics, Comm Events, does not need to fetch data normally like any of the Table views do)
// TODO: this is copy-paste from above, with one change that should instead be handled via the shouldFakeId list above. 
// refactor to not copy-paste.
const reduceRouteNoModels = (state, action) => {
  const id = payloadId(action.payload)
  const hasId = (id !== null) || shouldFakeId[action.type]
  const isNew = (hasId && id === 'new')
  const names = Object.assign({}, nameMap[action.type])
  const title = isNew ? ('New ' + capitalize(names.singular)) : ''

  return {
    models: [],
    isNew: isNew,
    isPending: false,    // if it's new there is no fetch to make
    isRefreshing: false,
    isSingle: hasId,
    modelId: id,
    requestId: generateRequestId().next().value,
    refreshFn: null,
    assoc: null,
    names: names,
    title: title,
    displayOptions: {
      ...state.displayOptions
    },
    meta: {
      ...state.meta
    }
  }
}

const reduceFetchSucceeded = (state, action) => {
  let result = state
  if (action.payload.requestId === state.requestId) {

    result = { ...state }

    let responseData = []
    try { responseData = action.payload.response.data } catch (e) {}

    if (state.isSingle && _.isArray(responseData) && responseData.length === 1) {
      result.models = [responseData[0]]
    } else if (state.isSingle && action.payload && !_.isArray(action.payload.response)) {
      result.models = [responseData]
    } else {
      result.models = responseData
    } 

    result.meta = action.payload.response.meta
    result.matchedDocs = action.payload.response.meta.nMatched
    result.isPending = false
    result.title = capitalize(result.isSingle ? ('Edit ' + result.names.singular) : result.names.plural)
  }
  return result
}

const reduceCacheRequestModel = (state, action) => {
  return { ...state, models: [action.payload.model] }
}

const setRefreshFn = (state, action) => {
  return {
    ...state,
    refreshFn: action.payload
  }
}

const setRequestMeta = (state, action) => {
  return {
    ...state,
    meta: {
      ...state.meta,
      ...action.payload
    }
  }
}

const reduceRefresh = (state, action) => {
  return {
    ...state,
    isRefreshing: true,
    requestId: generateRequestId().next().value
  }
}

const refreshSucceeded = (state, action) => {
  return {
    ...state,
    isRefreshing: false
  }
}

const reduceUpdateDisplayOptions = (state, action) => {
  return {
    ...state,
    displayOptions: {
      ...state.displayOptions,
      ...action.payload
    }
  }
}


export const reducer = handleActions({
  [ROUTE_ADMIN_USERS]: reduceRoute,
  [ROUTE_ADMIN_VEHICLES]: reduceRoute,
  [ROUTE_ADMIN_COMPANIES]: reduceRoute,
  [ROUTE_ADMIN_LOCATIONS]: reduceRoute,
  [ROUTE_ADMIN_PRICING_TIERS]: reduceRoute,
  [ROUTE_ADMIN_REVIEWS]: reduceRoute,
  [ROUTE_ADMIN_DRIVERS]: reduceRoute,
  [ROUTE_ADMIN_RESERVATIONS]: reduceRoute,
  [ROUTE_ADMIN_RESERVATIONS_OPTIMIZED]: reduceRoute,
  [ROUTE_ADMIN_SETTINGS]: reduceRouteNoModels,
  [ROUTE_ADMIN_MARKETS]: reduceRoute,
  [ROUTE_ADMIN_PROMOTIONS]: reduceRoute,
  [ROUTE_ADMIN_ZIPCODES]: reduceRouteNoModels,
  [ROUTE_ADMIN_DGRAPHS]: reduceRoute,
  [ROUTE_ADMIN_ANALYTICS]: reduceRouteNoModels,
  [ROUTE_ADMIN_FIND]: reduceRouteNoModels,
  [ROUTE_ADMIN_CHATS]: reduceRouteNoModels,
  [ROUTE_ADMIN_MULTI_VEHICLE_REQUEST]: reduceRouteNoModels,
  [ROUTE_ADMIN_BULK_VEHICLE_EDITS]: reduceRouteNoModels,
  [ROUTE_ADMIN_BULK_RESERVATION_EDITS]: reduceRouteNoModels,
  [ROUTE_ADMIN_CASH_PAYMENTS]: reduceRouteNoModels,
  [ROUTE_ADMIN_COMM_EVENTS]: reduceRouteNoModels,
  [ROUTE_ADMIN_MODEL_VALIDATIONS]: reduceRouteNoModels,
  [ROUTE_ADMIN_PENDING_ACCOUNTS]: reduceRoute,
  [ROUTE_ADMIN_PENDING_VEHICLES]: reduceRoute,

  [ADMIN_CACHE_REQUEST_MODEL]: reduceCacheRequestModel,
  [ADMIN_REFRESH_REQUEST_MODEL]: reduceRefresh,
  [ADMIN_SET_REFRESH_FN]: setRefreshFn,
  [ADMIN_SET_REQUEST_META]: setRequestMeta,
  [ADMIN_REFRESH_SUCCEEDED]: refreshSucceeded,

  [ADMIN_FETCH_DOCS_SUCCEEDED]: reduceFetchSucceeded,

  [ADMIN_UPDATE_DISPLAY_OPTIONS]: reduceUpdateDisplayOptions
}, initialState)
