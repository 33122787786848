import { createAction } from 'redux-actions'

import * as types from 'types'

// ROUTING
export const routeLogin = createAction(types.ROUTE_LOGIN)

// LOGIN
export const performLogin = createAction(
  types.PERFORM_LOGIN,
  (username, password, rememberMe) => ({ username, password, rememberMe })
)
export const performLoginWithIdToken = createAction(
  types.PERFORM_LOGIN_WITH_IDTOKEN,
  (idToken, redirect) => ({ idToken, redirect })
)
export const performLogout = createAction(types.PERFORM_LOGOUT)
export const acceptTC = createAction(types.ACCEPT_TERMS_CONDITIONS)
export const declinedTC = createAction(types.DECLINED_TERMS_CONDITIONS)
export const loginSuccess = createAction(types.LOGIN_SUCCESSFUL)
export const oktaLoginFailed = createAction(types.OKTA_LOGIN_FAILED)

// MESSAGING
export const addMessage = createAction(
  types.ADDED_MESSAGE,
  (id, text = '', type = null, linkDescription = '', linkUrl = '') => ({
    id,
    text,
    type,
    linkDescription,
    linkUrl,
  })
)
export const removeMessage = createAction(types.REMOVED_MESSAGE)

// FIND
export const findSetFleetAssetsOpen = createAction(types.SET_FLEET_ASSETS_OPEN)
export const findUpdatedCurrentPosition = createAction(
  types.UPDATED_CURRENT_POSITION
)
export const findMovedMarkerIntoView = createAction(
  types.MOVED_MARKER_INTO_VIEW
)
export const findUpdatedCurrentZoom = createAction(types.UPDATED_CURRENT_ZOOM)
export const findGetCurrentPosition = createAction(types.GET_CURRENT_POSITION)
export const findUpdatedFleetLocations = createAction(
  types.UPDATED_FLEET_LOCATIONS
)
export const findUpdatedFleetLocationAssets = createAction(
  types.UPDATED_VEHICLES
)
export const selectFleetLocation = createAction(types.SELECTED_FLEET_LOCATION)
// export const selectFleetLocationAndReCenter = createAction(types.SELECT_FLEET_LOCATION_AND_RECENTER)
export const findGetFleetLocationAssets = createAction(types.GET_VEHICLES)
export const findToggleFiltersOpen = createAction(types.TOGGLED_FILTERS_OPEN)
export const findUpdatedFilters = createAction(types.UPDATED_FILTERS)
export const findUpdatedFiltersAddress = createAction(
  types.UPDATED_FILTERS_ADDRESS
)
export const findResetFilters = createAction(types.RESET_FILTERS)
export const findSetSelectedPinLocation = createAction(
  types.SET_SELECTED_PIN_LOCATION
)
export const bookingUpdateDates = createAction(types.BOOKING_UPDATE_DATES)

// Fleet Images
export const fleetSetImages = createAction(
  types.FLEET_SET_IMAGES,
  (vehicleId, images, action) => ({ vehicleId, images, action })
)
export const updateFleetAssetById = createAction(types.FLEET_UPDATE_BY_ID)

// TODO maybe ACCOUNT and FLEET are one in the same, if so, merge with best terminology. ex. maybe hydrateFleet becomes accountHydrateFleet
// ACCOUNT
export const accountToggleFleetCoverageInsurance = createAction(
  types.ACCOUNT_TOGGLE_FLEET_COVERAGE_INSURANCE
)
export const accountVehicleSetOutOfStateValue = createAction(
  types.ACCOUNT_VEHICLE_SET_OUT_OF_STATE_TOGGLE
)
export const accountVehicleSetLocation = createAction(
  types.ACCOUNT_VEHICLE_SET_LOCATION
)
export const accountSetCanModifyAvailability = createAction(
  types.SET_CAN_MODIFY_AVAILABILITY
)
export const accountEnlistAssetStart = createAction(types.ENLIST_ASSET_START)
export const accountEnlistAssetNext = createAction(types.ENLIST_ASSET_NEXT)
export const accountEnlistAssetBack = createAction(types.ENLIST_ASSET_BACK)
export const accountEnlistAssetSubmit = createAction(types.ENLIST_ASSET_SUBMIT)
export const accountEnlistAssetExit = createAction(types.ENLIST_ASSET_EXIT)
export const accountAddLocation = createAction(
  types.ADD_LOCATION,
  (
    street1,
    street2,
    city,
    state,
    zip,
    lat,
    lng,
    phone,
    pickupInstructions
  ) => ({
    street1,
    street2,
    city,
    state,
    zip,
    lat,
    lng,
    phone,
    pickupInstructions,
  })
)
export const accountEditLocation = createAction(
  types.EDIT_LOCATION,
  (id, street1, street2, city, state, zip, phone, pickupInstructions) => ({
    id,
    street1,
    street2,
    city,
    state,
    zip,
    phone,
    pickupInstructions,
  })
)
export const accountLocationUpdated = createAction(
  types.EDIT_LOCATION_UPDATED,
  (location) => ({ location })
)
export const performUpdatePassword = createAction(
  types.PROFILE_UPDATE_PASSWORD,
  (newPassword, confirmNewPassword) => ({ newPassword, confirmNewPassword })
)

export const accountUpdateProfileImage = createAction(
  types.PROFILE_UPDATE_IMAGE,
  (profileImageUrl) => ({ profileImageUrl })
)

export const updateStateProfileImage = createAction(
  types.PROFILE_STATE_UPDATE_IMAGE,
  (profileImageUrl) => ({ profileImageUrl })
)

// FLEET
export const hydrateFleet = createAction(types.HYDRATED_FLEET, (data) => data)
export const updatedFilteredAssetCount = createAction(
  types.UPDATED_FILTERED_ASSET_COUNT
)
export const updatedAssetCount = createAction(types.UPDATED_ASSET_COUNT)
export const checkForPending = createAction(types.CHECK_PENDING)
export const unlistVehicle = createAction(types.UNLISTED_VEHICLE)

// LOCATIONS
export const hydrateLocations = createAction(types.HYDRATED_LOCATIONS)

// PROFILE
export const hydrateProfile = createAction(types.HYDRATED_PROFILE)

// RegisterForm
export const submitRegisterForm = createAction(
  types.SUBMIT_REGISTER_FORM_REQUEST,
  (
    firstName,
    lastName,
    company,
    title,
    phone,
    email,
    zipCode,
    toggleValue
  ) => ({
    firstName,
    lastName,
    company,
    title,
    phone,
    email,
    zipCode,
    toggleValue,
  })
)

// ADMIN
export const adminNavigateDashboard = createAction(types.ROUTE_ADMIN_DASHBOARD)
export const adminNavigateUsers = createAction(types.ROUTE_ADMIN_USERS)
export const adminNavigateVehicles = createAction(types.ROUTE_ADMIN_VEHICLES)
export const adminNavigateVehiclesBulkUpload = createAction(
  types.ROUTE_ADMIN_VEHICLES_BULK
)
export const adminNavigateCompanies = createAction(types.ROUTE_ADMIN_COMPANIES)
export const adminNavigateLocations = createAction(types.ROUTE_ADMIN_LOCATIONS)
export const adminNavigatePricingTiers = createAction(
  types.ROUTE_ADMIN_PRICING_TIERS
)
export const adminNavigateReviews = createAction(types.ROUTE_ADMIN_REVIEWS)
export const adminNavigateDrivers = createAction(types.ROUTE_ADMIN_DRIVERS)
export const adminNavigateReservations = createAction(
  types.ROUTE_ADMIN_RESERVATIONS
)
export const adminNavigateReservationsOptimized = createAction(
  types.ROUTE_ADMIN_RESERVATIONS_OPTIMIZED
)
export const adminNavigateSettings = createAction(types.ROUTE_ADMIN_SETTINGS)
export const adminNavigateMarkets = createAction(types.ROUTE_ADMIN_MARKETS)
export const adminNavigatePromotions = createAction(
  types.ROUTE_ADMIN_PROMOTIONS
)
export const adminNavigateZipcodes = createAction(types.ROUTE_ADMIN_ZIPCODES)
export const adminNavigateDGraphs = createAction(types.ROUTE_ADMIN_DGRAPHS)
export const adminNavigateAnalytics = createAction(types.ROUTE_ADMIN_ANALYTICS)
export const adminNavigateFind = createAction(types.ROUTE_ADMIN_FIND)
export const adminNavigateChats = createAction(types.ROUTE_ADMIN_CHATS)
export const adminNavigateMultiVehicleRequest = createAction(
  types.ROUTE_ADMIN_MULTI_VEHICLE_REQUEST
)
export const adminNavigateBulkVehicleEdits = createAction(
  types.ROUTE_ADMIN_BULK_VEHICLE_EDITS
)
export const adminNavigateBulkReservationEdits = createAction(
  types.ROUTE_ADMIN_BULK_RESERVATION_EDITS
)
export const adminNavigateBulkCashPayments = createAction(
  types.ROUTE_ADMIN_CASH_PAYMENTS
)
export const adminNavigateModelValidations = createAction(
  types.ROUTE_ADMIN_MODEL_VALIDATIONS
)
export const adminNavigateReservationStats = createAction(
  types.ROUTE_ADMIN_RESERVATION_STATS
)
export const adminNavigateCoopApi = createAction(types.ROUTE_ADMIN_COOP_API)
export const adminNavigateCacheReport = createAction(
  types.ROUTE_ADMIN_CACHE_REPORT
)
export const adminNavigateDevSketches = createAction(
  types.ROUTE_ADMIN_DEV_SKETCHES
)
export const adminNavigatePendingAccounts = createAction(
  types.ROUTE_ADMIN_PENDING_ACCOUNTS
)
export const adminNavigatePendingVehicles = createAction(
  types.ROUTE_ADMIN_PENDING_VEHICLES
)

export const adminFetchMe = createAction(types.ADMIN_FETCH_ME)
export const adminFetchMeSucceeded = createAction(
  types.ADMIN_FETCH_ME_SUCCEEDED
)

export const adminSetMyMarket = createAction(types.ADMIN_SET_MY_MARKET)

export const adminFetchDocsSucceeded = createAction(
  types.ADMIN_FETCH_DOCS_SUCCEEDED
)

export const adminFetchUsers = createAction(types.ADMIN_FETCH_USERS)
export const adminCreateUser = createAction(types.ADMIN_CREATE_USER)
export const adminUpdateUser = createAction(types.ADMIN_UPDATE_USER)
export const adminDeleteUser = createAction(types.ADMIN_DELETE_USER)
export const adminForgotPassword = createAction(types.ADMIN_FORGOT_PASSWORD)
export const adminCreateOktaAccount = createAction(
  types.ADMIN_CREATE_OKTA_ACCOUNT
)

// Welcome email related action creators
export const adminCreateUserAndSendVerifyEmail = createAction(
  types.ADMIN_CREATE_USER_AND_SEND_VERIFY_EMAIL
)
export const adminSendVerifyEmail = createAction(types.ADMIN_SEND_VERIFY_EMAIL)

export const adminFetchDrivers = createAction(types.ADMIN_FETCH_DRIVERS)
export const adminCreateDriver = createAction(types.ADMIN_CREATE_DRIVER)
export const adminUpdateDriver = createAction(types.ADMIN_UPDATE_DRIVER)
export const adminDeleteDriver = createAction(types.ADMIN_DELETE_DRIVER)

export const adminFetchVehicles = createAction(types.ADMIN_FETCH_VEHICLES)
export const adminFetchVehiclesWithCanModifyAvailability = createAction(
  types.ADMIN_FETCH_VEHICLES_WITH_CAN_MODIFY_AVAILABILITY
)
export const adminCreateVehicle = createAction(types.ADMIN_CREATE_VEHICLE)
export const adminUpdateVehicle = createAction(types.ADMIN_UPDATE_VEHICLE)
export const adminDeleteVehicle = createAction(types.ADMIN_DELETE_VEHICLE)
export const adminCreateAndRefreshVehicle = createAction(
  types.ADMIN_CREATE_REFRESH_VEHICLE
)
export const adminApproveVehicle = createAction(types.ADMIN_APPROVE_VEHICLE)

export const adminFetchLocations = createAction(types.ADMIN_FETCH_LOCATIONS)
export const adminCreateLocation = createAction(types.ADMIN_CREATE_LOCATION)
export const adminUpdateLocation = createAction(types.ADMIN_UPDATE_LOCATION)
export const adminDeleteLocation = createAction(types.ADMIN_DELETE_LOCATION)

export const adminFetchPricingTiers = createAction(
  types.ADMIN_FETCH_PRICING_TIERS
)
export const adminCreatePricingTier = createAction(
  types.ADMIN_CREATE_PRICING_TIER
)
export const adminUpdatePricingTier = createAction(
  types.ADMIN_UPDATE_PRICING_TIER
)
export const adminDeletePricingTier = createAction(
  types.ADMIN_DELETE_PRICING_TIER
)

export const adminFetchVehicleProps = createAction(
  types.ADMIN_FETCH_VEHICLE_PROPS
)
export const adminCreateVehicleProps = createAction(
  types.ADMIN_CREATE_VEHICLE_PROPS
)
// export const adminUpdateVehicleProps = createAction(types.ADMIN_UPDATE_VEHICLE_PROPS)
// export const adminDeleteVehicleProps = createAction(types.ADMIN_DELETE_VEHICLE_PROPS)

export const adminFetchZipcodes = createAction(types.ADMIN_FETCH_ZIPCODES)
export const adminFetchDGraphs = createAction(types.ADMIN_FETCH_DGRAPHS)
export const adminUpdateDGraphs = createAction(types.ADMIN_UPDATE_DGRAPHS)
export const adminCreateDGraphs = createAction(types.ADMIN_CREATE_DGRAPHS)
export const adminDeleteDGraphs = createAction(types.ADMIN_DELETE_DGRAPHS)

export const adminFetchReviews = createAction(types.ADMIN_FETCH_REVIEWS)
export const adminCreateReview = createAction(types.ADMIN_CREATE_REVIEW)
export const adminUpdateReview = createAction(types.ADMIN_UPDATE_REVIEW)
export const adminDeleteReview = createAction(types.ADMIN_DELETE_REVIEW)

export const adminFetchCompanies = createAction(types.ADMIN_FETCH_COMPANIES)
export const adminCreateCompany = createAction(types.ADMIN_CREATE_COMPANY)
export const adminUpdateCompany = createAction(types.ADMIN_UPDATE_COMPANY)
export const adminUpdateCompanyDNR = createAction(
  types.ADMIN_UPDATE_COMPANY_DNR
)
export const adminDeleteCompany = createAction(types.ADMIN_DELETE_COMPANY)

export const adminFetchReservations = createAction(
  types.ADMIN_FETCH_RESERVATIONS
)
export const adminCreateReservation = createAction(
  types.ADMIN_CREATE_RESERVATION
)
export const adminUpdateReservation = createAction(
  types.ADMIN_UPDATE_RESERVATION
)
export const adminDeleteReservation = createAction(
  types.ADMIN_DELETE_RESERVATION
)

export const adminFetchSettings = createAction(types.ADMIN_FETCH_SETTINGS)
export const adminFetchSettingsSucceeded = createAction(
  types.ADMIN_FETCH_SETTINGS_SUCCEEDED
)
export const adminUpdateSettings = createAction(types.ADMIN_UPDATE_SETTINGS)

export const adminFetchMarkets = createAction(types.ADMIN_FETCH_MARKETS)
export const adminCreateMarket = createAction(types.ADMIN_CREATE_MARKET)
export const adminUpdateMarket = createAction(types.ADMIN_UPDATE_MARKET)
export const adminDeleteMarket = createAction(types.ADMIN_DELETE_MARKET)

export const adminFetchPromotions = createAction(types.ADMIN_FETCH_PROMOTIONS)
export const adminCreatePromotion = createAction(types.ADMIN_CREATE_PROMOTION)
export const adminUpdatePromotion = createAction(types.ADMIN_UPDATE_PROMOTION)
export const adminDeletePromotion = createAction(types.ADMIN_DELETE_PROMOTION)

export const adminFetchAnalytics = createAction(types.ADMIN_FETCH_ANALYTICS)
export const adminFetchAnalyticsSucceeded = createAction(
  types.ADMIN_FETCH_ANALYTICS_SUCCEEDED
)

export const adminFetchCommEvents = createAction(types.ADMIN_FETCH_COMM_EVENTS)
export const adminFetchModelValidations = createAction(
  types.ADMIN_FETCH_MODEL_VALIDATIONS
)

export const adminCacheRequestModel = createAction(
  types.ADMIN_CACHE_REQUEST_MODEL
)
export const adminRefreshRequestModel = createAction(
  types.ADMIN_REFRESH_REQUEST_MODEL
)
export const adminSetRefreshFn = createAction(types.ADMIN_SET_REFRESH_FN)
export const adminSetRequestMeta = createAction(types.ADMIN_SET_REQUEST_META)
export const adminSetRequestMetaAndRefresh = createAction(
  types.ADMIN_SET_REQUEST_META_AND_REFRESH
)
export const adminRefreshSucceeded = createAction(types.ADMIN_REFRESH_SUCCEEDED)
export const adminApiCallFailed = createAction(types.ADMIN_API_CALL_FAILED)
export const adminDismissErrors = createAction(types.ADMIN_DISMISS_ERRORS)
export const adminPushIsBusy = createAction(types.ADMIN_PUSH_IS_BUSY)
export const adminPopIsBusy = createAction(types.ADMIN_POP_IS_BUSY)
export const adminClearIsBusy = createAction(types.ADMIN_CLEAR_IS_BUSY)

export const adminPushBreadcrumbs = createAction(types.ADMIN_PUSH_BREADCRUMBS)
export const adminPopBreadcrumbs = createAction(types.ADMIN_POP_BREADCRUMBS)
export const adminSetBreadcrumbs = createAction(types.ADMIN_SET_BREADCRUMBS)

export const adminUpdateDisplayOptions = createAction(
  types.ADMIN_UPDATE_DISPLAY_OPTIONS
)

export const adminFetchPendingAccounts = createAction(
  types.ADMIN_FETCH_PENDING_ACCOUNTS
)
export const adminCreatePendingAccount = createAction(
  types.ADMIN_CREATE_PENDING_ACCOUNT
)
export const adminUpdatePendingAccount = createAction(
  types.ADMIN_UPDATE_PENDING_ACCOUNT
)
export const adminDeletePendingAccount = createAction(
  types.ADMIN_DELETE_PENDING_ACCOUNT
)
export const adminVerifyPendingAccount = createAction(
  types.ADMIN_VERIFY_PENDING_ACCOUNT
)

// BOOKING
export const submitBooking = createAction(types.SUBMIT_BOOKING)
export const bookingError = createAction(types.SUBMIT_BOOKING_ERROR)
export const setBookingCostEstimate = createAction(
  types.SET_BOOKING_COST_ESTIMATE
)
export const setBookingUnavailability = createAction(
  types.SET_BOOKING_UNAVAILABILITY
)

// TOGGLES
export const addToggle = createAction(types.ADDED_TOGGLE)
export const removeToggle = createAction(types.REMOVED_TOGGLE)
export const addExclusiveToggle = createAction(types.ADDED_EXCLUSIVE_TOGGLE)

// RESERVATIONS
export const reservationReviewSubmit = createAction(
  types.RESERVATION_REVIEW_REQUEST
)

// BORROWING
export const hydrateBorrowing = createAction(types.HYDRATED_BORROWING)
export const hydrateReservationDetails = createAction(
  types.HYDRATED_RESERVATION_DETAILS
)
export const hydrateReservationTimeline = createAction(
  types.HYDRATED_RESERVATION_TIMELINE
)
export const resetReservationDetails = createAction(
  types.RESET_RESERVATION_DETAILS
)
export const hydrateDrivers = createAction(types.HYDRATED_DRIVERS)
export const setBorrowingDetails = createAction(types.SET_BORROWING_DETAILS)
export const addLenderOwner = createAction(types.ADDED_LENDER_OWNER)
// @NOTE These two may be reused by lending as some of the actions are simliar. In the same
// vein we can probably combine their sagas into a single reservations file.
export const cancelReservation = createAction(types.CANCEL_RESERVATION)
export const cancelExtensionRequest = createAction(
  types.CANCEL_EXTENSION_REQUEST
)

export const changedBorrowingDriver = createAction(
  types.CHANGED_BORROWING_DRIVER,
  (reservationId, driverId) => ({ reservationId, driverId })
)
export const changedBorrowingDetailsDriver = createAction(
  types.CHANGED_BORROWING_DETAILS_DRIVER,
  (reservationId, driverId) => ({ reservationId, driverId })
)

// LENDING
export const hydrateLending = createAction(types.HYDRATED_LENDING)
export const hydrateLendingById = createAction(types.HYDRATE_LENDING_BY_ID)
export const setLendingDetails = createAction(types.SET_LENDING_DETAILS)
export const addBorrowerOwner = createAction(types.ADDED_BORROWER_OWNER)
export const approveReservation = createAction(types.APPROVE_RESERVATION)
export const declineReservation = createAction(types.DECLINE_RESERVATION)
export const approveLendingReservation = createAction(
  types.APPROVE_LENDING_RESERVATION
)
export const declineLendingReservation = createAction(
  types.DECLINE_LENDING_RESERVATION
)

// BASE
export const hydrateBase = createAction(types.HYDRATED_BASE)
export const updateStripeBorrower = createAction(
  types.UPDATE_STRIPE_BORROWER,
  (borrower) => borrower
)
export const updateStripeLender = createAction(
  types.UPDATE_STRIPE_LENDER,
  (lender) => lender
)
export const setLenderDashboardLink = createAction(
  types.LENDER_SET_DASHBOARD_LINK,
  (dashboardUrl) => ({ dashboardUrl })
)
export const updateLenderHasPending = createAction(
  types.UPDATE_LENDER_HAS_PENDING,
  (value) => ({ value })
)
export const updateBorrowerHasPending = createAction(
  types.UPDATE_BORROWER_HAS_PENDING,
  (value) => ({ value })
)
export const baseReady = createAction(types.BASE_READY)
export const updateCoopInsurance = createAction(types.UPDATE_COOP_INSURANCE)

// DRIVERS
export const addDriver = createAction(types.ADDED_DRIVER)
export const updateDriver = createAction(types.UPDATED_DRIVER)

// Request extension
export const requestBorrowingExtension = createAction(
  types.REQUEST_BORROWING_EXTENSION,
  (id, newEndDate) => ({ id, newEndDate })
)
export const requestBorrowingDetailsExtension = createAction(
  types.REQUEST_BORROWING_DETAILS_EXTENSION,
  (id, newEndDate) => ({ id, newEndDate })
)
export const requestLendingExtension = createAction(
  types.REQUEST_LENDING_EXTENSION,
  (id, newEndDate, status) => ({ id, newEndDate, status })
)
export const requestLendingDetailsExtension = createAction(
  types.REQUEST_LENDING_DETAILS_EXTENSION,
  (id, newEndDate, status) => ({ id, newEndDate, status })
)

// Dispute
export const disputeRequest = createAction(types.SUBMIT_DISPUTE_REQUEST)

// PAYMENTS
export const storeCreditCard = createAction(
  types.STORE_CREDIT_CARD,
  (token, userId) => ({ token, userId })
)
export const chargeReservation = createAction(types.CHARGE_RESERVATION)
export const runTransfersForReservation = createAction(
  types.RUN_TRANSFERS_FOR_RESERVATION
)
export const authReservation = createAction(types.AUTH_RESERVATION)

// HELP
export const submitContactForm = createAction(
  types.SUBMIT_CONTACT_FORM,
  (reason, message, phone) => ({ reason, message, phone })
)

export const getGeolocation = createAction(types.GET_GEOLOCATION)

// GLOBAL
export const noOpAction = createAction('no-op')
export const setServerDbVersionSupport = createAction(
  types.SET_SERVER_DB_VERSION_SUPPORT
)

// GLOBAL/RESERVATIONS
export const sendReservationContract = createAction(
  types.SEND_RESERVATION_CONTRACT,
  (reservationId, contractType) => ({ reservationId, contractType })
)
export const sendDriverResSms = createAction(
  types.SEND_DRIVER_RES_SMS,
  (reservationId) => ({ reservationId })
)

// RESERVATIONS OPTIMIZED
export const adminFetchCompaniesNames = createAction(
  types.ADMIN_FETCH_COMPANIES_NAMES
)
export const adminFetchCompaniesNamesSucceeded = createAction(
  types.ADMIN_FETCH_COMPANIES_NAMES_SUCCEEDED
)

export const adminFetchReservationsOptimized = createAction(
  types.ADMIN_FETCH_RESERVATIONS_OPTIMIZED,
  ({ page }) => ({ page })
)
export const adminSetIsFetchingReservationsOptimized = createAction(
  types.ADMIN_FETCHING_RESERVATIONS_OPTIMIZED,
  (isFetching) => isFetching
)
export const adminFetchReservationsOptimizedSucceeded = createAction(
  types.ADMIN_FETCH_RESERVATIONS_OPTIMIZED_SUCCEEDED
)
export const adminLocallyDeleteReservation = createAction(
  types.ADMIN_LOCALLY_DELETE_RESERVATION
)
export const setFilters = createAction(
  types.ADMIN_SET_FILTERS,
  ({ filters }) => ({ filters })
)
export const resetReservationsOptimized = createAction(
  types.ADMIN_FETCHING_RESERVATIONS_OPTIMIZED_RESET
)
