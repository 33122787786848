import { fork, put, call, take, takeLatest } from 'redux-saga/effects'

import {
  init as dashboardInit,
  routeTrigger as dashboardRouteTrigger,
} from './dashboard'
import {
  init as driversInit,
  routeTrigger as driversRouteTrigger,
} from './drivers'
import {
  init as fleetAssetsInit,
  routeTrigger as fleetAssetsRouteTrigger,
} from './vehicles'
import {
  init as companiesInit,
  routeTrigger as companiesRouteTrigger,
} from './companies'
import {
  init as locationsInit,
  routeTrigger as locationsRouteTrigger,
} from './locations'
import {
  init as pricingTiersInit,
  routeTrigger as pricingTiersRouteTrigger,
} from './pricingTiers'
import {
  init as vehiclePropsInit,
  // routeTrigger as vehiclePropsRouteTrigger
} from './vehicleProps'
import {
  init as zipcodesInit,
  routeTrigger as zipcodesRouteTrigger,
} from './zipcodes'
import {
  init as dGraphsInit,
  routeTrigger as dGraphsRouteTrigger,
} from './dGraphs'
import {
  init as reviewsInit,
  routeTrigger as reviewsRouteTrigger,
} from './reviews'
import { init as usersInit, routeTrigger as usersRouteTrigger } from './users'
import {
  init as reservationsInit,
  routeTrigger as reservationsRouteTrigger,
} from './reservations'
import {
  init as reservationsOptimizedInit,
  routeTrigger as reservationsOptimizedRouteTrigger,
} from './reservationsOptimized'
import {
  init as settingsInit,
  routeTrigger as settingsRouteTrigger,
} from './settings'
import {
  init as marketsInit,
  routeTrigger as marketsRouteTrigger,
} from './markets'
import {
  init as promotionsInit,
  routeTrigger as promotionsRouteTrigger,
} from './promotions'
import {
  init as analyticsInit,
  routeTrigger as analyticsRouteTrigger,
} from './analytics'
import { routeTrigger as findRouteTrigger } from './find'
import {
  init as commEventsInit,
  routeTrigger as commEventsRouteTrigger,
} from './commEvents'
import {
  init as modelValidationsInit,
  routeTrigger as modelValidationsRouteTrigger,
} from './modelValidations'
import { init as requestInit } from './request'
import {
  init as pendingAccountsInit,
  routeTrigger as pendingAccountsRouteTrigger,
} from './pendingAccounts'
import {
  adminFetchMe,
  adminFetchMeSucceeded,
  adminRefreshRequestModel,
  adminPushIsBusy,
  adminPopIsBusy,
} from 'actions'

import api from '../../utils/api-v2'

import {
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_VEHICLES,
  ROUTE_ADMIN_VEHICLES_BULK,
  ROUTE_ADMIN_COMPANIES,
  ROUTE_ADMIN_LOCATIONS,
  ROUTE_ADMIN_PRICING_TIERS,
  ROUTE_ADMIN_REVIEWS,
  ROUTE_ADMIN_DRIVERS,
  ROUTE_ADMIN_RESERVATIONS,
  ROUTE_ADMIN_RESERVATIONS_OPTIMIZED,
  ROUTE_ADMIN_SETTINGS,
  ROUTE_ADMIN_MARKETS,
  ROUTE_ADMIN_PROMOTIONS,
  ROUTE_ADMIN_ZIPCODES,
  ROUTE_ADMIN_DGRAPHS,
  ROUTE_ADMIN_ANALYTICS,
  ROUTE_ADMIN_FIND,
  ROUTE_ADMIN_COMM_EVENTS,
  ROUTE_ADMIN_MODEL_VALIDATIONS,
  ROUTE_ADMIN_PENDING_ACCOUNTS,
  ROUTE_ADMIN_PENDING_VEHICLES,
  ADMIN_FETCH_ME,
  ADMIN_FETCH_ME_SUCCEEDED,
  ADMIN_SET_MY_MARKET,
  ADMIN_FETCH_DOCS_SUCCEEDED,
  USER_ID,
  ROUTE_ADMIN_CHATS,
  ROUTE_ADMIN_RESERVATION_STATS,
  ROUTE_ADMIN_CACHE_REPORT,
  ROUTE_ADMIN_COOP_API,
  ROUTE_ADMIN_MULTI_VEHICLE_REQUEST,
  ROUTE_ADMIN_BULK_VEHICLE_EDITS,
  ROUTE_ADMIN_BULK_RESERVATION_EDITS,
  ROUTE_ADMIN_CASH_PAYMENTS,
} from 'types'

import CoopRouteSagas from '../CoopRouteSagas'

import { adminGroupAuthFilter, billingAuthFilter } from '../authentication'
import { identifyUserInAppcues } from 'utils/appcues'

function* fetchMe() {
  const userId = yield window.localStorage.getItem(USER_ID)
  const user = yield call([api, 'get'], `/crud/users/${userId}`)
  identifyUserInAppcues(user.data.userName)
  yield put(adminFetchMeSucceeded(user))
}

function* setMyMarket(action) {
  const userId = yield window.localStorage.getItem(USER_ID)
  try {
    // push busy.
    yield put(adminPushIsBusy())

    // call() should block
    yield call([api, 'patch'], `/crud/users/${userId}`, {
      market: action.payload,
    })

    yield fork(function* () {
      // when fetch me has succeeded ...
      yield take(ADMIN_FETCH_ME_SUCCEEDED)

      yield fork(function* () {
        // when our refresh request has succeeded ...
        yield take(ADMIN_FETCH_DOCS_SUCCEEDED)
        // pop busy.
        yield put(adminPopIsBusy())
      })

      // refresh the active request
      yield put(adminRefreshRequestModel())
    })

    // refetch me
    yield put(adminFetchMe())
  } catch (error) {
    yield put(adminPopIsBusy())
    console.log('could not set market', error)
  }
}

// wire up core actions we repsond to. seems ever so slightly lame.
let adminInited = false
function* initAdmin() {
  if (!adminInited) {
    adminInited = true
    yield fork(dashboardInit)
    yield fork(driversInit)
    yield fork(fleetAssetsInit)
    yield fork(companiesInit)
    yield fork(locationsInit)
    yield fork(pricingTiersInit)
    yield fork(vehiclePropsInit)
    yield fork(zipcodesInit)
    yield fork(dGraphsInit)
    yield fork(reviewsInit)
    yield fork(usersInit)
    yield fork(reservationsInit)
    yield fork(reservationsOptimizedInit)
    yield fork(settingsInit)
    yield fork(marketsInit)
    yield fork(promotionsInit)
    yield fork(analyticsInit)
    yield fork(commEventsInit)
    yield fork(modelValidationsInit)
    yield fork(requestInit)
    yield fork(pendingAccountsInit)

    yield fork(function* () {
      yield takeLatest(ADMIN_FETCH_ME, fetchMe)
    })
    yield fork(function* () {
      yield takeLatest(ADMIN_SET_MY_MARKET, setMyMarket)
    })
  }
}

// Tying admin saga stuff (which was always separate
// from the rest of the app) to the current way the app
// wants to do its saga set-up.
class AdminRouteSagas extends CoopRouteSagas {
  constructor(routeTriggerFn) {
    super()
    this.authFilter = adminGroupAuthFilter
    this.routeTriggerFn = routeTriggerFn
  }
  *initListeners() {
    yield initAdmin()
  }

  *handleRouteHit() {
    const routeTriggerFn = this.routeTriggerFn
    // wait for fetch me to finish so that our first request
    // for our docs includes any user-related filters (eg, user market)
    yield fork(function* () {
      yield take(ADMIN_FETCH_ME_SUCCEEDED)
      if (routeTriggerFn) {
        yield routeTriggerFn()
      }
    })
    // fetch me
    yield put(adminFetchMe())
  }
}

/**
 * Specialized admin-based routing for billing admins
 */
class BillingAdminRouteSagas extends AdminRouteSagas {
  constructor(routeTriggerFn) {
    super(routeTriggerFn)
    this.authFilter = billingAuthFilter
  }
}

export const adminRouteTriggers = {
  [ROUTE_ADMIN_DASHBOARD]: new AdminRouteSagas(dashboardRouteTrigger),
  [ROUTE_ADMIN_USERS]: new AdminRouteSagas(usersRouteTrigger),
  [ROUTE_ADMIN_VEHICLES]: new AdminRouteSagas(fleetAssetsRouteTrigger),
  [ROUTE_ADMIN_VEHICLES_BULK]: new AdminRouteSagas(),
  [ROUTE_ADMIN_COMPANIES]: new AdminRouteSagas(companiesRouteTrigger),
  [ROUTE_ADMIN_LOCATIONS]: new AdminRouteSagas(locationsRouteTrigger),
  [ROUTE_ADMIN_PRICING_TIERS]: new BillingAdminRouteSagas(
    pricingTiersRouteTrigger
  ),
  [ROUTE_ADMIN_REVIEWS]: new AdminRouteSagas(reviewsRouteTrigger),
  [ROUTE_ADMIN_DRIVERS]: new AdminRouteSagas(driversRouteTrigger),
  [ROUTE_ADMIN_RESERVATIONS]: new AdminRouteSagas(reservationsRouteTrigger),
  [ROUTE_ADMIN_RESERVATIONS_OPTIMIZED]: new AdminRouteSagas(
    reservationsOptimizedRouteTrigger
  ),
  [ROUTE_ADMIN_SETTINGS]: new AdminRouteSagas(settingsRouteTrigger),
  [ROUTE_ADMIN_MARKETS]: new AdminRouteSagas(marketsRouteTrigger),
  [ROUTE_ADMIN_PROMOTIONS]: new AdminRouteSagas(promotionsRouteTrigger),
  [ROUTE_ADMIN_ZIPCODES]: new AdminRouteSagas(zipcodesRouteTrigger),
  [ROUTE_ADMIN_DGRAPHS]: new AdminRouteSagas(dGraphsRouteTrigger),
  [ROUTE_ADMIN_ANALYTICS]: new AdminRouteSagas(analyticsRouteTrigger),
  [ROUTE_ADMIN_FIND]: new AdminRouteSagas(findRouteTrigger),
  [ROUTE_ADMIN_COMM_EVENTS]: new AdminRouteSagas(commEventsRouteTrigger),
  [ROUTE_ADMIN_MODEL_VALIDATIONS]: new AdminRouteSagas(
    modelValidationsRouteTrigger
  ),
  [ROUTE_ADMIN_PENDING_ACCOUNTS]: new AdminRouteSagas(
    pendingAccountsRouteTrigger
  ),
  [ROUTE_ADMIN_PENDING_VEHICLES]: new AdminRouteSagas(fleetAssetsRouteTrigger),
  [ROUTE_ADMIN_CHATS]: new AdminRouteSagas(),
  [ROUTE_ADMIN_MULTI_VEHICLE_REQUEST]: new AdminRouteSagas(),
  [ROUTE_ADMIN_BULK_VEHICLE_EDITS]: new AdminRouteSagas(),
  [ROUTE_ADMIN_RESERVATION_STATS]: new AdminRouteSagas(),
  [ROUTE_ADMIN_COOP_API]: new AdminRouteSagas(),
  [ROUTE_ADMIN_CACHE_REPORT]: new AdminRouteSagas(),
  [ROUTE_ADMIN_BULK_RESERVATION_EDITS]: new AdminRouteSagas(),
  [ROUTE_ADMIN_CASH_PAYMENTS]: new AdminRouteSagas(),
}
