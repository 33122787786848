import { connectRoutes, NOT_FOUND } from 'redux-first-router'
import createHistory from 'history/createBrowserHistory'
import queryString from 'query-string'

import {
  ROUTE_ADMIN_ANALYTICS,
  ROUTE_ADMIN_CACHE_REPORT,
  ROUTE_ADMIN_CHATS,
  ROUTE_ADMIN_MULTI_VEHICLE_REQUEST,
  ROUTE_ADMIN_BULK_VEHICLE_EDITS,
  ROUTE_ADMIN_COMM_EVENTS,
  ROUTE_ADMIN_COMPANIES,
  ROUTE_ADMIN_COOP_API,
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_ADMIN_DEV_SKETCHES,
  ROUTE_ADMIN_DGRAPHS,
  ROUTE_ADMIN_DRIVERS,
  ROUTE_ADMIN_FIND,
  ROUTE_ADMIN_LOCATIONS,
  ROUTE_ADMIN_MARKETS,
  ROUTE_ADMIN_MODEL_VALIDATIONS,
  ROUTE_ADMIN_PENDING_ACCOUNTS,
  ROUTE_ADMIN_PENDING_VEHICLES,
  ROUTE_ADMIN_PRICING_TIERS,
  ROUTE_ADMIN_PROMOTIONS,
  ROUTE_ADMIN_RESERVATION_STATS,
  ROUTE_ADMIN_RESERVATIONS_OPTIMIZED,
  ROUTE_ADMIN_RESERVATIONS,
  ROUTE_ADMIN_REVIEWS,
  ROUTE_ADMIN_SETTINGS,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_VEHICLES_BULK,
  ROUTE_ADMIN_VEHICLES,
  ROUTE_ADMIN_ZIPCODES,
  ROUTE_LOGIN,
  ROUTE_LOGIN_CALLBACK,
  ROUTE_ADMIN_BULK_RESERVATION_EDITS,
  ROUTE_ADMIN_CASH_PAYMENTS,
} from 'types'

export const routesMap = {
  [NOT_FOUND]: '/404',
  [ROUTE_LOGIN]: '/',
  [ROUTE_LOGIN_CALLBACK]: '/login/callback',
  [ROUTE_ADMIN_DASHBOARD]: '/admin/(dashboard)?',
  [ROUTE_ADMIN_USERS]: '/admin/users/:modelId?',
  [ROUTE_ADMIN_VEHICLES_BULK]: '/admin/vehicles/bulk-vehicles-upload',
  [ROUTE_ADMIN_VEHICLES]: '/admin/vehicles/:assoc?/:modelId?',
  [ROUTE_ADMIN_COMPANIES]: '/admin/companies/:assoc?/:modelId?',
  [ROUTE_ADMIN_LOCATIONS]: '/admin/locations/:assoc?/:modelId?',
  [ROUTE_ADMIN_PRICING_TIERS]: '/admin/pricing-tiers/:assoc?/:modelId?',
  [ROUTE_ADMIN_REVIEWS]: '/admin/reviews/:assoc?/:modelId?',
  [ROUTE_ADMIN_DRIVERS]: '/admin/drivers/:assoc?/:modelId?',
  [ROUTE_ADMIN_RESERVATIONS]: '/admin/reservations/:assoc?/:modelId?',
  [ROUTE_ADMIN_RESERVATIONS_OPTIMIZED]:
    '/admin/reservations-opt/:assoc?/:modelId?',
  [ROUTE_ADMIN_SETTINGS]: '/admin/settings',
  [ROUTE_ADMIN_MARKETS]: '/admin/markets/:assoc?/:modelId?',
  [ROUTE_ADMIN_PROMOTIONS]: '/admin/promotions/:assoc?/:modelId?',
  [ROUTE_ADMIN_ZIPCODES]: '/admin/zipcodes/:assoc?/:modelId?',
  [ROUTE_ADMIN_DGRAPHS]: '/admin/d-graphs/:assoc?/:modelId?',
  [ROUTE_ADMIN_ANALYTICS]: '/admin/analytics',
  [ROUTE_ADMIN_FIND]: '/admin/find',
  [ROUTE_ADMIN_CHATS]: '/admin/chats',
  [ROUTE_ADMIN_MULTI_VEHICLE_REQUEST]: '/admin/multi-vehicle-request',
  [ROUTE_ADMIN_BULK_VEHICLE_EDITS]: '/admin/bulk-vehicle-edits',
  [ROUTE_ADMIN_COMM_EVENTS]: '/admin/comm-events',
  [ROUTE_ADMIN_MODEL_VALIDATIONS]: '/admin/model-validations',
  [ROUTE_ADMIN_RESERVATION_STATS]: '/admin/reservation-stats',
  [ROUTE_ADMIN_COOP_API]: '/admin/coop-api',
  [ROUTE_ADMIN_CACHE_REPORT]: '/admin/cache-report',
  [ROUTE_ADMIN_PENDING_ACCOUNTS]: '/admin/pending-accounts/:modelId?',
  [ROUTE_ADMIN_PENDING_VEHICLES]: '/admin/pending-vehicles/:modelId?',
  [ROUTE_ADMIN_DEV_SKETCHES]: '/admin/dev-sketches',
  [ROUTE_ADMIN_BULK_RESERVATION_EDITS]: '/admin/bulk-reservation-edits',
  [ROUTE_ADMIN_CASH_PAYMENTS]: '/admin/bulk-cash-payments',
}

export const { reducer, middleware, enhancer } = connectRoutes(
  routesMap,
  {
    querySerializer: queryString,
  }
)
